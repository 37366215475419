var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loadingGetFares,
      "spinner-variant": 'success'
    }
  }, [_c('b-card', {
    staticClass: "cursor-pointer scroll-container d-flex flex-row overflow-auto gap-2 px-75",
    attrs: {
      "no-body": ""
    }
  }, [_vm._l(_vm.trains, function (train) {
    return [_c('div', {
      key: "train-".concat(train.id),
      class: "my-50 py-25 px-50 rounded-lg rounded-lg shadow text-nowrap d-flex flex-column justify-content-between\n          ".concat(_vm.isSelectedTrain(train.id) ? 'border-success-2' : 'border-light-2', "\n          "),
      on: {
        "click": function click($event) {
          return _vm.selectTrainHandle(train);
        }
      }
    }, [_c('div', {
      staticClass: "text-center fw-800 text-info"
    }, [_vm._v(" Tàu " + _vm._s(train.trainCode) + " ")]), _c('div', {
      staticClass: "my-25 mx-auto"
    }, [_c('b-badge', {
      staticClass: "fw-700 px-1 pt-50",
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v(" " + _vm._s(_vm.calculateDurationTrain(train.departureTime, train.arrivalTime)) + " ")])], 1), _c('div', {
      staticClass: "my-25"
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('train.search.result.departureStation')) + ": "), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(_vm._s(train.departureName))])]), _vm._v(" ("), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(train.departureTime).timeDate) + " ")]), _vm._v(") ")]), _c('div', {
      staticClass: "my-25"
    }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t('train.search.result.arrivalStation')) + ": "), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(_vm._s(train.arrivalName))])]), _vm._v(" ("), _c('span', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(train.arrivalTime).timeDate) + " ")]), _vm._v(") ")])])];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }