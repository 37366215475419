<template>
  <IAmOverlay
    :loading="loadingGetFares"
    :spinner-variant="'success'"
  >
    <b-card
      class="cursor-pointer scroll-container d-flex flex-row overflow-auto gap-2 px-75"
      no-body
    >
      <template v-for="train of trains">
        <div
          :key="`train-${train.id}`"
          :class="`my-50 py-25 px-50 rounded-lg rounded-lg shadow text-nowrap d-flex flex-column justify-content-between
            ${isSelectedTrain(train.id) ? 'border-success-2' : 'border-light-2'}
            `"
          @click="selectTrainHandle(train)"
        >
          <div class="text-center fw-800 text-info">
            Tàu {{ train.trainCode }}
          </div>

          <div class="my-25 mx-auto">
            <b-badge
              variant="light-success"
              class="fw-700 px-1 pt-50"
            >
              {{ calculateDurationTrain(train.departureTime, train.arrivalTime) }}
            </b-badge>
          </div>

          <div class="my-25">
            <div>
              {{ $t('train.search.result.departureStation') }}: <span class="fw-700">{{ train.departureName }}</span>
            </div>
            (<span class="fw-700">
              {{ convertISODateTime(train.departureTime).timeDate }}
            </span>)
          </div>

          <div class="my-25">
            <div>
              {{ $t('train.search.result.arrivalStation') }}: <span class="fw-700">{{ train.arrivalName }}</span>
            </div>
            (<span class="fw-800">
              {{ convertISODateTime(train.arrivalTime).timeDate }}
            </span>)
          </div>
        </div>
      </template>
    </b-card>
  </IAmOverlay>
</template>

<script>
import { BCard, BBadge } from 'bootstrap-vue'
import moment from 'moment'

import store from '@/store'
import IAmOverlay from '@/components/IAmOverlay.vue'
import { convertISODateTime } from '@/@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BBadge,
    IAmOverlay,
  },
  props: {
    itineraryIndex: {
      type: Number,
      required: true,
    },
    trains: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      loadingGetFares,
      stateTrainSelectedArray,
    } = useTrainHandle()

    const isSelectedTrain = id => stateTrainSelectedArray.value.some(train => train.id === id)

    function selectTrainHandle(train) {
      store.commit('app-train/SET_SELECTED_TRAIN', train)
    }

    function calculateDurationTrain(departureTime, arrivalTime) {
      const start = moment(departureTime)
      const end = moment(arrivalTime)
      const duration = moment.duration(end.diff(start))
      const days = duration.days()
      const hours = duration.hours()
      const minutes = duration.minutes()

      const result = [
        days > 0 ? `${days} ngày` : '',
        hours > 0 ? `${hours} giờ` : '',
        minutes > 0 ? `${minutes} phút` : '',
      ]
        .filter(Boolean)
        .join(' ')
      return result || '0 phút'
    }

    return {
      loadingGetFares,
      stateTrainSelectedArray,
      selectTrainHandle,
      isSelectedTrain,
      convertISODateTime,
      calculateDurationTrain,
    }
  },
}
</script>

<style lang="scss">
/* Custom scrollbar cho Chrome, Safari */
.scroll-container::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom scrollbar cho Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.full-height-img {
  height: 100%; /* Đặt chiều cao của b-img bằng 100% của thẻ div */
  width: auto; /* Đảm bảo tỷ lệ không bị thay đổi */
}

.promotion_class {
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
}
</style>
